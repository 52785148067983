<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
    <Body>
      <noscript
        ><iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-W6Q7NQWS"
          height="0"
          width="0"
          style="display: none; visibility: hidden"
        ></iframe
      ></noscript>
      <!-- <LayoutTopHeader :showSelect="false" />
      <div class="logo-header">
        <div class="logo-box">
          <LocLink to="/">
            <img src="~/assets/img/logo.png" alt="image" class="img-fluid" />
          </LocLink>
        </div>
      </div> -->
      <LayoutHeader />
      <slot />
    </Body>
  </Html>
</template>
<script setup lang="ts">
const head = useLocaleHead({
  addDirAttribute: true, // Adds dir
  addSeoAttributes: true, // Adds lang
});
const htmlAttrs = computed(() => head.value.htmlAttrs!);
</script>
<style lang="scss" scoped>
.logo-header {
  padding: 14px 20px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  display: none;
  @include media(772) {
    & {
      display: block;
    }
  }
}
</style>
